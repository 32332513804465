@import "_default_colors";

.card {
  display: grid;
  grid-template: 1fr / 1fr;
  // box-shadow: 0 0 3px 0.05rem #b5e853;
  // transition: 0.3s;
  width: 100%;
  overflow: hidden;
  padding: 15px 15px 15px 15px;
  background: rgba(0,0,0,0.9);
  border: 1px solid #b5e853;
  // border-radius: 2rem;
  height: fit-content;
  margin: 1rem 0rem 1rem 0rem;
  align-items: center;
}

.card img {
  padding: 0 !important;
  object-fit: contain;
  max-width: 75% !important;
}

/*.card:hover,
.card:focus {
  box-shadow: 0 0px 5px 0.1rem #b5e853;
}*/

.card-body {
  padding-left: 1rem;
  overflow: auto;
}

.card-container {
  display: grid;
  grid-template: repeat(1, 1fr) / repeat(6, 1fr);
  width: 90%;
  margin: 0 auto;
  gap: 1rem;
  justify-items: center;
  justify-content: center;
}

.card-container .card {
  grid-column: span 2;
  display: grid;
  grid-template: 25% 1fr / 1fr;
  width: 100%;
  height: 100%;
  justify-items: center;
  align-items: start;
  padding: 0;
  margin: 0;

  /* Dealing with 2 orphan items */
  &:last-child:nth-child(3n-1) {
    grid-column-end: -2;
  }
  &:nth-last-child(2):nth-child(3n + 1) {
		grid-column-end: 4;
	}

	/* Dealing with single orphan */
	&:last-child:nth-child(3n - 2) {
		grid-column-end: 5;
	}

}

.card-container .card-body {
  padding-left: 0rem;
  text-align: center;
}

@media only screen and (max-width: 810px) {
  .card {
    display: grid;
    grid-template: 1fr / 1fr;
    // transition: 0.3s;
    width: 100%;
    overflow: hidden;
    padding: 15px 15px 15px 15px;
    width: fit-content;
    justify-items: center;
  }

  .card-container {
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template: repeat(6, auto) / repeat(1, 1fr);
    column-gap: 0;
  }

  .card-container .card {
    grid-column: 1;
    max-width: 100%;
  }

}