
#results-container {
    margin: 0;
    position: fixed;
    top: 5rem;
    right: 0;
    flex-flow: column;
    width: 25%;
    background-color: #151515;
    display: flex;
    transform: translateX(200%);
    transition: transform 0.3s, opacity 0.3s;
    border: 1px solid #b5e853;
    gap: 0;
    max-height: calc(50% - 5rem);
    overflow: scroll;
}

#search:not(:placeholder-shown) ~ #results-container {
    transform: none;
}

#search-container {
    order: 1;
}

@media only screen and (max-width: 810px) {
    #search-container{
        text-align: center;
        border-bottom: 1px solid #b5e853;
        border-top: 1px solid #b5e853;
        padding: 1rem 0 1rem 0;
        width: 100%;
        order: 0;
    }

    #results-container {
        width: 100%;
        text-align: left;
        border-bottom: 1px solid #b5e853;
        border-left: 0px;
        border-right: 0px;
        top: 8rem;
    }

    #results-container a {
        border: 0px;
    }
}