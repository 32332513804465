.email_form {
    display: grid;
    grid-template: auto auto auto auto 5rem 2rem / repeat(2, minmax(10%, auto));
    max-width: 100%;
    background: #000;
    border: 3px solid #b5e853;
    border-radius: 10px;
    gap: 1rem;
    padding: 1rem;
    justify-content: center;
}

.email_form #body-label {
    grid-column: 1 / span 2;
}

.email_form textarea {
    grid-column: 1 / span 2;
}

.email_form .btn {
    min-width: fit-content;    
    max-width: 0;
    justify-self: center;
}

.email_form input:not([type="submit"]) {
    min-height: 1rem;
    max-height: 0;
}

.email_form #gotcha{
    display: none;
}

.email_form select {
    min-height: 1.5rem;
    max-height: 0;
}