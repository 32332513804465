@import "rouge-base16-dark";
@import "default_colors";
@import "cards";
@import "share-bar";
@import "toc";
@import "donate-button";
@import "contact_form";
@import "navbar";
@import "search";
@import "./fontawesome/fontawesome";
@import "./fontawesome/solid";
@import "./fontawesome/brands";

$body-background: $cod-grey !default;
$body-foreground: $gallery !default;
$header: $conifer !default;
$blockquote-color: $silver-chalice !default;
$blockquote-border: $dove-grey !default;

/*body {
  display: flex;
  margin: 0;
  padding: 0;
  //background: $body-background url("../images/bkg.png") 0 0;
  background: #151515;
  color: $body-foreground;
  font-size: 16px;
  line-height: 1.6;
  font-family: Monaco, "Bitstream Vera Sans Mono", "Lucida Console", Terminal, monospace;
}*/


body {
  display: flex;
  flex-flow: column;
  margin: 0;
  padding: 0;
  background: $body-background url("../images/bkg.png") 0 0;
  color: $body-foreground;
  font-size: 16px;
  line-height: 1.6;
  font-family: Monaco, "Bitstream Vera Sans Mono", "Lucida Console", Terminal, monospace;
}

/* ParticlesJS */

#particles-js {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* General & 'Reset' Stuff */

.container {
  display: flex;
  flex-flow: row;
  justify-content: center;
  width: 90%;
  align-self: center;
  min-height: 100vh;
}

section {
  display: block;
  margin: 0 0 20px 0;
}

/*h1, h2, h3, h4, h5, h6 {
  margin: 0 0 20px;
}*/

li {
  line-height: 1.5 ;
}

li li{
  list-style-type: lower-alpha;
}
li li li{
  list-style-type: lower-roman;
}



/* Main Content
*/

.main_content {
  display: flex;
  flex-flow: column;
  max-width: 50%;
  -webkit-font-smoothing: antialiased;
  padding: 2rem 1rem 100px 1rem;
  word-wrap:break-word;
}

.main_content.post {
  background: rgba(0, 0, 0, 0.7);
  border-left: 1px solid #b5e853;
  border-right: 1px solid #b5e853;
}

@media only screen and (max-width: 810px) {
  .main_content {
    max-width: 100%;
    padding-top: 2rem;
  }
}

.main_content img {
  max-width: 100%
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-family: Monaco, "Bitstream Vera Sans Mono", "Lucida Console", Terminal, monospace;
  color: $header;
  letter-spacing: -0.03em;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1),
               0 0 5px rgba(181, 232, 83, 0.1),
               0 0 10px rgba(181, 232, 83, 0.1);
}

.main_content h1 {
  font-size: 30px;
}

.main_content h2 {
  font-size: 25px;
}

.main_content h3 {
  font-size: 20px;
}

.main_content h4 {
  font-size: 16px;
}

.main_content h5 {
  font-size: 16px;
  text-transform: uppercase;
  margin: 0 0 5px 0;
}

.main_content h6 {
  font-size: 16px;
  text-transform: uppercase;
  color: #999;
  margin: 0 0 5px 0;
}

.main_content img + em {
  display:block;
  margin-left:auto;
  margin-right:auto;
  text-align:center;
  padding: 0px 0px 20px 0px;
}

.main_content img {
  display:block;
  margin-left:auto;
  margin-right:auto;
  margin:0 auto;
  padding: 20px 0px 20px 0px;
}

.main_content div {
  max-width: 100%;
}

dt {
  font-style: italic;
  font-weight: bold;
}

ul li {
  list-style-image:url('../images/bullet.png');
}

blockquote {
  color: $blockquote-color;
  padding-left: 10px;
  border-left: 1px dotted $blockquote-border;
}

pre {
  background: #151515;
  border: 1px solid rgba(255, 255, 255, 0.15);
  padding: 10px;
  font-size: 16px;
  color: #b5e853;
  border-radius: 2px;
  word-wrap: normal;
  overflow: auto;
  overflow-y: hidden;
}

code.highlighter-rouge {
  background: #151515;
  border: 1px solid rgba(255, 255, 255, 0.15);
  padding: 0px 3px;
  margin: 0px -3px;
  color: #aa759f;
  border-radius: 2px;
  overflow: auto;
  max-width: 100%;
 }

.table {
  border: 1px solid rgba(255, 255, 255, 0.15);
  padding: 10px;
  font-size: 16px;
  display: flex;
  overflow-x: auto;
  background-color: #151515;
}

table {
  width: 100%;
  margin: 0 0 20px 0;
}

th {
  border-bottom: 1px dashed #b5e853;
  text-align: left;
  padding: 5px 10px;
}

td {
  padding: 5px 10px;
}

hr {
  height: 0;
  border: 0;
  border-bottom: 1px dashed #b5e853;
  color: #b5e853;
}

/* Buttons
*/

.btn {
  display: inline-block;
  background-color: #000;
  padding: 8px 18px;
  border-radius: 50px;
  border: 2px solid #b5e853;
  color: #eaeaea;
  font-weight: bold;
  font-size: 13px;
  text-decoration: none;
  transition: 0.3s;
}

.btn:hover {
  background-color: #b5e853;
  color: #151515;
}

.btn .icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 1px 8px 0 0;
  float: left;
}

.btn-github .icon {
  opacity: 0.6;
  background: url("../images/blacktocat.png") 0 0 no-repeat;
}

/* Links
   a, a:hover, a:visited
*/

a {
  color: #63c0f5;
  text-shadow: 0 0 5px rgba(104, 182, 255, 0.5);
}

/* Clearfix */

.cf:before, .cf:after {
  content:"";
  display:table;
}

.cf:after {
  clear:both;
}

.cf {
  zoom:1;
}

#a-title {
  text-decoration: none;
}
