/* Navbar */

/*.navbar {
    height: 100vh;
    background-color: #151515;
    display: flex;
    flex-flow: column;
    align-items: center;
    min-width: min-content;
    max-width: 10%;
    position: sticky;
    top: 0px;
    border-right: 1px solid #b5e853;
    overflow: auto;
  }
  
  .navbar img {
    max-height:50%;
    max-width: 50%;
    margin: 2em 2em 2em 2em;
  }
  
  .navbar a {
    color: #eaeaea;
    font-size: 1.2rem;
  }
  
  .navbar .r {
    font-size: 2rem;
    padding: 0 1rem 0 1rem;
    box-shadow: 0 0 1px #777;
    max-width: fit-content;
    align-self: center;
    transition: background-color 200ms linear;
  }
  .navbar .r:hover {
    color: #fff;
    background-color: #ff5700;
  }
  
  .link-list {
    display: flex;
    flex-flow: column;
    gap: 0.5rem;
  }*/


.navbar {
    width: 100%;
    box-sizing: border-box;
    background-color: #151515;
    padding: 0 2rem 0 10vh;
    height: 5rem;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    border-bottom: 1px solid #b5e853;
    transition: top 0.3s;
    overflow: auto;
}

.navbar a {
    color: #eaeaea;
    font-size: 1rem;
}

.nav-title {
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 100%;
    max-width: 50%;
    text-decoration: none;
}

.nav-img {
    max-height: 50%;
    margin: 1rem;
}

.link-list {
    display: flex;
    flex-flow: row;
    justify-content: right;
    margin-right: 1rem;
    gap: 1rem;
    align-items: center;
}

.link-list-mobile-button {
    display: none;
}

.navbar .r {
    display: inline-block;
    -webkit-box-shadow: 0 0 1px #777;
    box-shadow: 0 0 1px #777;
    padding: 5px 12px;
    margin-right: 0;
    margin-bottom: 0;
    text-decoration: none; 
    margin-right: 0;
}

.navbar .r:hover {
    text-decoration: none;
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear; 
}

@media only screen and (max-width: 810px) {
    .navbar {
        padding: 0 2rem 0 2rem;
    }

    .link-list-mobile-button input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        width: 20px;
        z-index: 1;
        align-self: center;
    }

    .link-list {
        justify-content: center;
        align-items: center;
        margin: 0;
        position: fixed;
        top: 5rem;
        left: 0;
        flex-flow: column;
        width: 100%;
        align-items: center;
        background-color: #151515;
        display: flex;
        transform: translateX(200%);
        transition: transform 0.3s, opacity 0.3s;
        border-bottom: 1px solid #b5e853;
        gap: 0;
    }

    .link-list a {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #b5e853;
        padding: 1rem 0 1rem 0;
    }

    #search-container:focus-within ~ a {
        display: none;
    }

    .link-list .r {
        font-size: 1rem;
        padding: 1rem 0 1rem 0;
    }

    .link-list-mobile-button.checked ~ #link-list  {
        transform: none;
    }
      
    .link-list-mobile-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 15px;
        transition: transform 0.3s;
        height: 100%;
        padding: 0 20px 0 20px;
    }

    .link-list-mobile-button span {
        display: flex;
        border: 1px solid white;
        margin: 2px;
        width: 100%;
        transition: transform 0.3s,
                    opacity 0.3s;
    }

    .link-list-mobile-button #click:checked ~ span
    {
        opacity: 1;
        transform: translate(0, -6px) rotate(225deg);
    }

    .link-list-mobile-button #click:checked ~ span:nth-last-child(3)
    {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }

    .link-list-mobile-button #click:checked ~ span:nth-last-child(2)
    {
        transform: rotate(-225deg) translate(0px, 0px);
    }

    .nav-title h1 {
        display: none;
    }
    
    .nav-img {
        margin: 0;
    }
}
