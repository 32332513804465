@import "_default_colors";

.donateButton {
  display: flex;
  align-items: center;
  background: $conifer;
  color: #151515;
  width: max-content;
  height: 46px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border-radius: 5rem;
  transition: 0.3s;
  position: fixed;
  bottom: 5%;
  left: 5%;
  margin: 0 auto;
  cursor: pointer;
}

@keyframes panelFrames {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

.donationPanel {
  opacity: 1;
  position:fixed;
  width: 20rem;
  height: 550px;
  bottom: 15%;
  left: 5%;
  transition: opacity 0.1s linear;
  animation: 0.3s ease-out 0s 1 panelFrames;
}
