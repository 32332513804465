@import "_default_colors";

#markdown-toc::before {
	content: "Contents";
	font-weight: bold;
}

#markdown-toc {
	border: 1px solid #aaa;
	padding: 1.5em 1.5em 1.5em 3em;
    display: inline-block;
	width:fit-content;
}

#markdown-toc li {
	line-height: 1.5;
}
