@import "_default_colors";

.share-box a {
  display: inline-block;
  -webkit-box-shadow: 0 0 1px #777;
  box-shadow: 0 0 1px #777;
  padding: 5px 12px;
  margin-right: 5px;
  margin-bottom: 5px;
  text-decoration: none; 
}
  
.share-box a:hover {
  text-decoration: none;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear; 
}

.f {
  color: $conifer;
  font-size: 25px;
  margin-right: 1.5rem;
}
  .f:hover {
    color: #fff;
    background-color: #3b5998; }

.t {
  color: $conifer; 
  font-size: 25px;
  margin-right: 1.5rem;
}
  .t:hover {
    color: #fff;
    background-color: #4099FF; }

.g {
  color: $conifer; 
  font-size: 25px;
  margin-right: 1.5rem;
}
  .g:hover {
    color: #151515;
    background-color: #fff; }

.r {
  color: $conifer; 
  font-size: 25px;
  margin-right: 1.5rem;
}
  .r:hover {
    color: #fff;
    background-color: #ff5700; }

.l {
  color: $conifer; 
  font-size: 25px;
  margin-right: 1.5rem;
}
  .l:hover {
    color: #fff;
    background-color: #0077b5; }

.e {
  color: $conifer; 
  font-size: 25px;
  margin-right: 1.5rem;
}
  .e:hover {
    color: #fff;
    background-color: #444444; }
